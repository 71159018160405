import './bootstrap';
import '../css/app.css';
import HoneybadgerVue from '@honeybadger-io/vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {createApp, DefineComponent, h} from 'vue';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => appName,
    resolve: (name) => name.match(/^(Auth|Profile)\//)
        ? resolvePageComponent(`../../core/resources/js/Pages/${name}.vue`, import.meta.glob<DefineComponent>('../../core/resources/js/Pages/**/*.vue'))
        : resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const isDevelopmentEnvironment = import.meta.env.VITE_APP_ENV === 'local';
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue);

        if (!isDevelopmentEnvironment) {
            app.use(HoneybadgerVue, {
                apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
                environment: import.meta.env.VITE_APP_ENV,
                revision: import.meta.env.VITE_HONEYBADGER_REVISION,
                developmentEnvironments: ['local', 'testing'],
            });
        }

        app.mount(el);

        if (!isDevelopmentEnvironment) {
            // @ts-ignore
            const pageData = JSON.parse(el.getAttribute('data-page')).props;

            if (pageData.auth.user) {
                // @ts-ignore
                app.$honeybadger.setContext({
                    user_id: pageData.auth.user.id,
                    user_name: pageData.auth.user.name,
                    user_email: pageData.auth.user.email,
                });
            }
        }
    },
    progress: {
        color: '#4B5563',
    },
});
